import { classifyData } from '@/utils/utils'

export function classfiyRole(list) {
  let obj = {}
  if (Array.isArray(list)) {
    list.forEach((item) => {
      if (obj[item.role]) {
        obj[item.role].child.push(item)
      } else {
        obj[item.role] = {
          role: item.role,
          child: [item],
        }
      }
    })
  }
  return obj
}

// 获取 车间(池、池、池)、车间(池、池、池)
export function getWs(p_list) {
  let arr = []

  let ws = classifyData(p_list, 'ws_key', 'ws_name')

  ws.forEach((item) => {
    let arr1 = []
    item.children.forEach((item1) => {
      arr1.push(item1.p_name)
    })
    arr.push(`${item.ws_name}(${arr1.join('、')})`)
  })

  return arr.join('、') || '暂无'
}

// 判断车运是否为空
export function isSTripEmpty(item) {
  if (
    item.s_starting_time ||
    item.s_start_point ||
    item.s_end_point ||
    item.s_name ||
    item.s_phone ||
    item.s_price
  ) {
    return false
  } else {
    return true
  }
}

// 判断空运是否为空
export function isLTripEmpty(item) {
  if (
    item.l_starting_time ||
    item.l_start_point ||
    item.l_end_point ||
    item.l_name ||
    item.l_phone ||
    item.l_price
  ) {
    return false
  } else {
    return true
  }
}
