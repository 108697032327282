<template>
  <div class="main">
    <yy-navbar :tab-title="tabTitle" />
    <yiyu-form
      :_this="thisAlias"
      :form="form"
      :formOpts="formOpts"
      @submit="onSubmit"
      ref="form"
      class="relative"
      v-myLoad="isLoading"
    >
      <template v-slot:footer>
        <fixed-btn
          :btnTxt="status == 'add' ? '确认新增' : '保存'"
          @click="toSubmit"
          :loading="btnLoading"
          :disabled="btnLoading"
          :leftTxt="status == 'edit' ? '删除' : undefined"
          @leftClick="del"
        ></fixed-btn>
      </template>
    </yiyu-form>
    <datetime-popup
      :date.sync="form[curDateKey]"
      :show.sync="showDatePicker"
      :title="dateTitle"
      type="date"
      @confirm="showDatePicker = false"
      @cancel="showDatePicker = false"
    >
    </datetime-popup>
  </div>
</template>

<script>
import fixedBtn from '@/components/button/fixedBtn.vue'
import datetimePopup from '@/components/popup/datetime.vue'
import yiyuForm from '@/components/form/yiyuForm'
import { YDialog } from 'yiyu-vant-ui/lib/dialog'

import { Dialog } from 'vant'
export default {
  name: 'carEdit',
  components: {
    yiyuForm,
    datetimePopup,
    fixedBtn,
  },
  data() {
    return {
      thisAlias: this,
      isLoading: false,
      btnLoading: false,
      status: 'add',
      dateTitle: '请选择日期',
      curDateKey: undefined,
      showDatePicker: false,
      form: {
        car_key: undefined, // '', // 车辆key
        license_plate: undefined, // '车牌',
        c_model: undefined, // '车型',
        load: undefined, // '限载量',
        remark: undefined, // '备注',
        purchase_date: null, // '购买时间',
        check_date: null, // '年检时间',
        pre_mt_date: null, // '上次保养时间',
        next_mt_date: null, // '下次保养时间',
      },
    }
  },
  computed: {
    tabTitle() {
      if (this.status == 'add') {
        return '新增车辆'
      } else {
        return '编辑车辆'
      }
    },
    formOpts() {
      return [
        {
          title: '',
          child: [
            {
              label: '车牌号',
              required: true,
              value: this.form.license_plate,
              name: 'license_plate',
            },
            {
              label: '车类型',
              placeholder: '请输入车类型，如大货车、SUV',
              value: this.form.c_model,
              name: 'c_model',
            },
            {
              label: '车载量',
              value: this.form.load,
              name: 'load',
            },
            {
              label: '购买日期',
              value: this.$dayjs(this.form.purchase_date).format('YYYY/MM/DD'),
              name: 'purchase_date',
              formType: 'select',
              click: () => {
                this.dateTitle = '购买日期'
                this.curDateKey = 'purchase_date'
                this.showDatePicker = true
              },
            },
            {
              label: '车检日期',
              value: this.$dayjs(this.form.check_date).format('YYYY/MM/DD'),
              name: 'check_date',
              formType: 'select',
              click: () => {
                this.dateTitle = '车检日期'
                this.curDateKey = 'check_date'
                this.showDatePicker = true
              },
            },
            {
              label: '上次保养',
              value: this.$dayjs(this.form.pre_mt_date).format('YYYY/MM/DD'),
              name: 'pre_mt_date',
              formType: 'select',
              click: () => {
                this.dateTitle = '上次保养'
                this.curDateKey = 'pre_mt_date'
                this.showDatePicker = true
              },
            },
            {
              label: '下次保养',
              value: this.$dayjs(this.form.next_mt_date).format('YYYY/MM/DD'),
              name: 'next_mt_date',
              formType: 'select',
              click: () => {
                this.dateTitle = '下次保养'
                this.curDateKey = 'next_mt_date'
                this.showDatePicker = true
              },
            },
            {
              label: '备注',
              value: this.form.remark,
              name: 'remark',
            },
          ],
        },
      ]
    },
  },
  created() {
    if (this.$route.query) {
      let { car_key } = this.$route.query
      this.form.car_key = car_key
      if (this.form.car_key) {
        this.status = 'edit'
        this.getData()
      } else {
        this.status = 'add'
      }
    }
  },
  methods: {
    getData() {
      this.isLoading = true
      this.$get('/car/getCar', {
        car_key: this.form.car_key,
      }).then((res) => {
        this.isLoading = false
        if (res.code == 10000) {
          let data = res.data
          // 数据响应
          for (let key in this.form) {
            data[key] = data[key] || this.form[key]
          }
          this.form = data
        }
      })
    },
    onSubmit() {
      this.btnLoading = true
      this.$post('/car/addCar', this.form).then((res) => {
        this.btnLoading = false
        if (res.code == 10000) {
          if (this.IS_BACK) {
            this.$toast.success(this.tabTitle + '成功')
            this.$go(-1, this)
          } else {
            this.nextPage()
          }
        }
      })
    },
    toSubmit() {
      this.$refs.form.$refs.yiyuForm.submit()
    },
    nextPage() {
      let base = [
        {
          key: '车运列表',
          onConfirm: () => {
            this.toPage('transport', 1)
          },
          handle: 'TRANSPORT_S_TRIP',
        },
        {
          key: '空运列表',
          onConfirm: () => {
            this.toPage('transport', 2)
          },
          handle: 'TRANSPORT_L_TRIP',
        },
        {
          key: '车辆列表',
          onConfirm: () => {
            this.toPage('transport', 3)
          },
          handle: 'TRANSPOERT_CAR',
        },
        {
          key: '发送通知',
          onConfirm: this.notice,
          handle: 'NOTICE',
        },
      ]
      if (this.status == 'edit') {
        base.push({
          key: '返回入口',
          onConfirm: () => {
            this.$go(-1, this)
          },
        })
      }
      YDialog({
        type: 'list',
        title: this.tabTitle + '成功',
        subtitle: '请选择下一步',
        data: this.FILTER_HANDLE(base),
      })
    },
    notice() {
      let p = '1'
      let title = this.tabTitle

      let remark = [
        {
          label: '车牌号',
          value: this.form.license_plate,
        },
      ]

      this.toNotice({ p, title, remark })
    },
    toPage(type, item) {
      switch (type) {
        case 'transport':
          this.$toPage(
            'transport',
            {
              tab: item,
            },
            {
              route_type: '$replace',
            },
          )
          break
      }
    },
    del() {
      Dialog.confirm({
        title: '是否确认删除该车辆',
      })
        .then(() => {
          if (this.postLoading) return
          this.postLoading = true
          this.$post('/car/delCar', {
            car_key: this.form.car_key,
          }).then((res) => {
            this.postLoading = false
            if (res.code == 10000) {
              this.$toast.success('删除车辆成功')
              this.$go(-1, this)
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    },
  },
}
</script>

<style></style>
