<template>
  <list-container
    @more="getMoreData"
    @refresh="refreshData"
    class="list-container"
    :pullDisable="true"
  >
    <yy-navbar />
    <van-tabs v-model="tabName" @change="tabChange">
      <van-tab
        :title="item.title + '(' + item.total + ')'"
        :name="item.name"
        v-for="(item, key) in tabData"
        :key="key"
      ></van-tab>
    </van-tabs>
    <y-filter
      :data="filterData"
      ref="filter"
      @search="fetchFilterData"
      @reset="resetData"
      v-if="tabName != 3"
    >
      <YFilterItemDatetimePicker
        slot="运输日期"
        :onChange="dateChange"
        type="date"
        :value.sync="sDate"
      />
    </y-filter>
    <van-search
      v-else
      @search="refreshData"
      v-model="kw"
      placeholder="可搜索车牌号"
    ></van-search>
    <div class="list relative" v-myLoad="isLoading">
      <div
        class="list-item-wrap"
        v-for="(item, index) in list"
        :key="index + 'list-item'"
        @click="toPage('detail', item)"
      >
        <div v-if="tabName != '3'">
          <div
            class="row"
            v-for="(item1, index1) in getDataObj(item)"
            :key="index1 + 'row'"
          >
            <div class="value bold" v-for="(value, key) in item1" :key="key">
              {{ value }}
            </div>
          </div>
          <div
            class="row"
            v-for="(item1, index1) in item.contacts"
            :key="index1 + 'cc'"
          >
            <div class="value">{{ item1.name }}</div>
            <div class="value">{{ item1.phone }}</div>
          </div>
          <div
            class="wrap"
            v-for="(item1, index1) in item.lm_list"
            :key="index1 + 'wrap'"
          >
            <div class="row">
              <div class="value">{{ item1.batch }}-{{ item1.mc_name }}</div>
            </div>
            <div class="row">
              <div class="value">关联：{{ item1.re_count || 0 }}万</div>
              <div class="value">{{ item1.box || 0 }}箱</div>
            </div>
            <div class="row">
              <div class="value">出苗：{{ item1.h_count || 0 }}万</div>
              <div class="value">{{ item1.h_box || 0 }}箱</div>
            </div>
          </div>

          <YLayoutBottomInfo
            :key="index"
            :name="item.opt_user"
            :time="item[tabData[tabName]['time']]"
          ></YLayoutBottomInfo>
        </div>
        <div v-else>
          <div class="row-between row">
            <div class="value bold">
              {{ item.license_plate || '暂无车牌号' }}
            </div>
            <div>
              {{ transStatus[item.status] }}
            </div>
          </div>
          <data-field
            :data-obj="getDataObj(item)"
            type="row"
            :filter="true"
          ></data-field>
          <div class="row-between">
            <div></div>
            <div
              class="main-c"
              v-if="IS_HANDLE('TRANSPOERT_CAR_EDIT')"
              @click.stop="toPage('edit', item)"
            >
              编辑
            </div>
          </div>
        </div>
      </div>
      <yiyu-empty v-if="!list.length"></yiyu-empty>
      <van-divider v-if="list.length >= total && total > 10"
        >已经到底啦</van-divider
      >
    </div>
    <drag-btn @add="toPage('addCar')" v-if="tabName == 3"></drag-btn>
  </list-container>
</template>

<script>
import dragBtn from '@/components/button/dragBtn'
import dataField from '@/components/layout/dataField.vue'
import { YLayoutBottomInfo } from 'yiyu-vant-ui'
import yiyuEmpty from '@/components/layout/empty.vue'
import listContainer from '@/components/layout/listContainer'
import { YFilter, YFilterItemDatetimePicker } from 'yiyu-vant-ui/lib/filter'
import { mapState } from 'vuex'
export default {
  name: 'transport',
  components: {
    listContainer,
    yiyuEmpty,
    YLayoutBottomInfo,
    YFilter,
    YFilterItemDatetimePicker,
    dataField,
    dragBtn,
  },
  data() {
    return {
      isLoading: false,
      kw: undefined,
      lm_id: undefined,
      tabName: '1',
      list: [],
      page: 1,
      count: 10,
      s_total: 0,
      l_total: 0,
      c_total: 0,
      scroll: 0,
      baseFilterData: [
        {
          label: '出发地',
          keyName: 'start_point',
          remote: async () => {
            const {
              data: { TranPos },
            } = await this.$get('/conf/getSelectConf', {
              select_items: ['TranPos'],
              params: {
                t_type: this.tabName, // 1 车运， 2 空运
                p_type: 1, // 1 出发地， 2 目的地
              },
            })

            return [
              {
                label: '',
                keyName: 'start_point',
                children: TranPos?.value.map((item) => ({
                  label: item.point,
                  key: item.point,
                })),
              },
            ]
          },
        },
        {
          label: '目的地',
          keyName: 'end_point',
          remote: async () => {
            const {
              data: { TranPos },
            } = await this.$get('/conf/getSelectConf', {
              select_items: ['TranPos'],
              params: {
                t_type: this.tabName, // 1 车运， 2 空运
                p_type: 2, // 1 出发地， 2 目的地
              },
            })

            return [
              {
                label: '',
                keyName: 'end_point',
                children: TranPos?.value.map((item) => ({
                  label: item.point,
                  key: item.point,
                })),
              },
            ]
          },
        },
        {
          label: '车辆',
          keyName: 'car_key',
          remote: async () => {
            const {
              data: { list },
            } = await this.$get('/car/getCarList')

            return [
              {
                label: '',
                keyName: 'car_key',
                children: list.map((item) => ({
                  label: `${item.license_plate}(${item.c_model || '无车型'})`,
                  key: item.car_key,
                })),
              },
            ]
          },
        },
        {
          label: '航班号',
          keyName: 'number',
          remote: async () => {
            const {
              data: { list },
            } = await this.$get('opt/getTransportOptList', {
              type: 2,
            })

            let numberList = [
              ...new Set(list.filter((item) => Boolean(item.number))),
            ]

            return [
              {
                label: '',
                keyName: 'number',
                children: numberList.map((item) => ({
                  label: item.number,
                  key: item.number,
                })),
              },
            ]
          },
        },
        {
          label: '运输日期',
          keyName: 'date',
        },
      ],
      sDate: new Date(),
    }
  },
  computed: {
    ...mapState({
      transStatus: (state) => state.transport.transStatus,
    }),
    total() {
      return this.tabData[this.tabName]['total']
    },
    tabData() {
      return {
        1: {
          title: '车运',
          name: '1',
          total: this.s_total,
          time: 's_starting_time',
        },
        2: {
          title: '空运',
          name: '2',
          total: this.l_total,
          time: 'l_starting_time',
        },
        3: {
          title: '车辆',
          name: '3',
          total: this.c_total,
        },
      }
    },
    filterData({ baseFilterData }) {
      let label = '航班号'
      if (this.tabName == 2) label = '车辆'
      return baseFilterData.filter((item) => item.label != label)
    },
  },
  mounted() {
    this.mountedFn()
    // 主要是为了拿统计数量
    this.getTransData({ page: 1, count: this.page * this.count }, true)
    this.getCarData({ page: 1, count: this.page * this.count }, true)
  },
  activated() {
    this.mountedFn()
    this.getData(1, this.page * this.count)
    this.$nextTick(() => {
      setTimeout(() => {
        if (document.querySelector('.list-container')) {
          document.querySelector('.list-container').scrollTop = this.scroll
        }
      }, 0)
    })
  },
  beforeRouteLeave(to, from, next) {
    this.scroll = document.querySelector('.list-container').scrollTop
    next()
  },
  methods: {
    tabChange() {
      this.page = 1
      this.count = 10
      this.list = []
      this.getData()
    },
    mountedFn() {
      this.activated = true
      if (this.$route.query) {
        this.lm_id = this.$route.query.lm_id
      }
      if (this.$route.params) {
        this.tabName = String(this.$route.params.tab ?? '') || this.tabName
      }
    },
    dateChange(e) {
      return this.$dayjs(e).format('YYYY/MM/DD')
    },
    // 接口文档：https://www.teambition.com/project/5f62bb6525c7d900446550c9/app/5eba5fba6a92214d420a3219/workspaces/5f62bb65b1a1060016c84d94/docs/60ab626d4e168300010a8d36
    getData(page, count) {
      let page1 = page || this.page
      let count1 = count || this.count
      let data = {
        page: page1,
        count: count1,
      }
      this.isLoading = true
      if (this.tabName == 3) {
        this.getCarData(data, count)
      } else {
        this.getTransData(data, count)
      }
    },
    getTransData(data, count) {
      Object.assign(data, {
        ...this.filterParams,
        lm_id: this.lm_id,
        type: this.tabName,
      })
      this.$get('/opt/getTransportOptList', data).then((res) => {
        if (this.tabName != 3) {
          this.dataDeal(res, count)
        }

        this.s_total = res.data.stat?.s_count
        this.l_total = res.data.stat?.l_count
        this.isLoading = false
      })
    },
    getCarData(data, count) {
      Object.assign(data, { keyword: this.kw })
      this.$get('/car/getCarList', data).then((res) => {
        if (this.tabName == 3) {
          this.dataDeal(res, count)
        }

        this.c_total = res.data.total
        this.isLoading = false
      })
    },

    dataDeal(res, count) {
      if (res.code == 10000) {
        let list = res.data.list
        if (count) {
          this.list = list
        } else {
          this.list.push(...list)
        }
      }
    },
    getMoreData() {
      if (this.list.length < this.total) {
        this.page++
        this.getData()
      }
    },
    fetchFilterData(e) {
      this.filterParams = e
      this.refreshData()
    },
    resetData() {
      this.filterParams = {}
      this.refreshData()
    },
    refreshData() {
      this.page = 1
      this.count = 10
      this.list = []
      this.getData()
    },
    toPage(type, item = {}) {
      switch (type) {
        case 'detail':
          if (this.tabName == 3) {
            this.toPage('edit', item)
          } else {
            this.$toPage(this, 'transDetail', {
              ha_key: item.ha_key,
              trip_type: this.tabName,
              cached: true,
            })
          }
          break
        case 'edit':
          item.cached = true
          this.$toPage(this, 'carEdit', item)
          break
        case 'addCar':
          item.cached = true
          this.$toPage(this, 'carEdit', item)
          break
      }
    },
    getDataObj(item) {
      if (this.tabName == 1) {
        return [
          {
            0: this.$dayjs(item.s_starting_time).format('YY-MM-DD') || '暂无',
            1: `第${item.car_num || 0}车(共${item.box}箱)`,
          },
          {
            0: this.$dayjs(item.s_starting_time).format('HH:mm') || '暂无',
            1: `${item.s_start_point || '暂无'}到${item.s_end_point || '暂无'}`,
          },
        ]
      } else if (this.tabName == 2) {
        return [
          {
            0: this.$dayjs(item.l_starting_time).format('YY-MM-DD') || '暂无',
            1: `${item.number || '暂无航班号'}(共${item.box}箱)`,
          },
          {
            0: this.$dayjs(item.l_starting_time).format('HH:mm') || '暂无',
            1: `${item.l_start_point || '暂无'}到${item.l_end_point || '暂无'}`,
          },
        ]
      } else if (this.tabName == 3) {
        return [
          {
            descText: '车型',
            data: item.c_model,
          },
          {
            descText: '载重量',
            data: item.load,
          },
          {
            descText: '购买',
            data: this.$dayjs(item.purchase_date).format('YYYY-MM-DD'),
          },
          {
            descText: '车检',
            data: this.$dayjs(item.check_date).format('YYYY-MM-DD'),
          },
          {
            descText: '上次保养',
            data: this.$dayjs(item.pre_mt_date).format('YYYY-MM-DD'),
          },
          {
            descText: '下次保养',
            data: this.$dayjs(item.next_mt_date).format('YYYY-MM-DD'),
          },
          {
            descText: '备注',
            data: item.remark,
          },
        ]
      }
    },
  },
}
</script>
<style lang="less" scoped>
.search {
  display: flex;
  align-items: center;
  flex-basis: 100%;
  justify-content: space-between;

  .van-search {
    flex-basis: 100%;
  }
}
.row-between {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.list {
  .list-item-wrap {
    padding: 0.5rem;
    border-bottom: 1px solid @lineColor;

    .wrap {
      padding: 0.2rem;
    }

    .row {
      display: flex;
      align-items: center;
      margin: 0.1rem 0;
      line-height: 1.4rem;

      .label {
        color: @textColor;
        font-weight: bold;
        opacity: 0.7;
      }

      .value {
        color: @textColor;
        font-size: 1rem;
        margin-left: 0.5rem;

        &.bold {
          font-weight: 700;
          color: @titleColor;
          font-size: 1.1rem;
        }
      }
    }

    .handles {
      display: flex;
      .handle-btn {
        border: 1px solid @lineColor;
        margin: 0 0.5rem;
        padding: 0 0.4rem;
        border-radius: 0.5rem;
        .txt {
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
