<template>
  <div class="main">
    <yy-navbar />
    <div class="main-content" v-myLoad="isLoading">
      <section class="info">
        <div class="row-between">
          <div
            class="title main-c"
            @click="toPage('batch', form)"
            v-if="form.batch"
          >
            {{ form.batch }}-{{ form.mc_name }}
          </div>
          <div class="title">{{ tripTypeObj[trip_type] }}详情</div>
          <div class="more">
            <van-popover
              v-model="showPopover"
              theme="dark"
              trigger="click"
              :actions="actions"
              @select="selectMore"
              placement="bottom-end"
            >
              <template #reference>
                <van-icon name="weapp-nav" />
              </template>
            </van-popover>
          </div>
        </div>

        <data-field :data-obj="[getDataObj(form)]" type="row" :filter="true">
          <template #car>
            <template v-if="form.car_list && form.car_list.length">
              <span
                class="car main-c"
                v-for="(item, index) in form.car_list"
                :key="index"
                @click="showCarDetail(item)"
                >{{ item.license_plate }}</span
              >
            </template>
            <template v-else>暂无</template>
          </template>
        </data-field>
        <!-- 无运输信息 -->
        <div class="main-c" v-if="isTripEmpty()">
          暂无运输信息
          <div
            class="main-c"
            @click="toPage('editTrans', form)"
            v-if="
              (trip_type == 1 && IS_HANDLE('TRANSPORT_S_TRIP_EDIT')) ||
              (trip_type == 2 && IS_HANDLE('TRANSPORT_L_TRIP_EDIT'))
            "
          >
            ，点击填写
          </div>
        </div>
      </section>
      <section class="rele">
        <div class="row-between">
          <div class="section-title">
            运输列表({{ (form.lm_list && form.lm_list.length) || 0 }})
          </div>
          <div class="info-item">
            <div class="label">合计：</div>
            <div class="value">{{ form.sum_box || 0 }}箱</div>
          </div>
        </div>
        <van-divider v-if="!form.lm_list || !form.lm_list"
          >暂无数据</van-divider
        >
        <div v-for="(item, index) in form.lm_list" :key="index">
          <div class="main-c" @click="toPage('batch', item)">
            {{ item.batch }}-{{ item.mc_name }}
          </div>
          <data-field
            :data-obj="[getDataObj(item, 'list')]"
            type="row"
          ></data-field>
        </div>
      </section>
    </div>
    <van-dialog v-model="carShow" :title="carData.license_plate">
      <data-field
        :data-obj="[getDataObj(carData, 'carDetail')]"
        type="row"
        :filter="true"
      ></data-field>
    </van-dialog>
  </div>
</template>

<script>
import dataField from '@/components/layout/dataField.vue'
import { isSTripEmpty, isLTripEmpty } from '@/utils/business'
import { Dialog } from 'vant'
import { call } from '@/utils/call'
import { sectionToChinese } from '@/utils/utils'
export default {
  name: 'transDetail',
  components: {
    dataField,
  },
  data() {
    return {
      form: {},
      ha_key: undefined,
      trip_type: undefined,
      tripTypeObj: {
        1: '车运',
        2: '空运',
      },

      showPopover: false,
      isLoading: false,
      carData: {},
      carShow: false,
    }
  },
  created() {
    if (this.$route.query) {
      let query = this.$route.query
      this.trip_type = query.trip_type
      this.ha_key = query.ha_key
      if (this.ha_key) {
        this.getData()
      }
    }
  },
  computed: {
    actions() {
      let prefix = this.trip_type == 1 ? 'S_' : 'L_'
      return this.FILTER_HANDLE([
        {
          text: '编辑',
          key: 'edit',
          handle: `TRANSPORT_${prefix}TRIP_EDIT`,
        },
        {
          text: '删除',
          key: 'del',
          handle: `TRANSPORT_${prefix}TRIP_DEL`,
        },
      ])
    },
  },
  methods: {
    getData() {
      this.isLoading = true
      this.$get('/opt/getTransportOptDetails', {
        ha_key: this.ha_key,
        type: this.trip_type,
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false
          this.form = res.data
        }
      })
    },
    selectMore(action) {
      switch (action.key) {
        case 'del':
          {
            let message = '确认删除？'
            Dialog.confirm({
              // title: "标题",
              message,
            })
              .then(() => {
                this.$post('/harvest/delHA', {
                  ha_key: this.form.ha_key,
                }).then((res) => {
                  if (res.code == 10000) {
                    this.$toast.success('删除成功')
                    this.$go(-1, this)
                  }
                })
              })
              .catch(() => {
                // on cancel
              })
          }
          break
        case 'edit':
          this.toPage('editTrans')
          break
      }
    },
    toPage(type, item) {
      switch (type) {
        case 'batch':
          this.$toPage(this, 'larvaProduct', item)
          break
        case 'editTrans':
          this.$toPage(this, 'transEdit', {
            ha_key: this.ha_key,
            type: this.trip_type,
          })
          break
        case 'phone':
          call(item)
          break
      }
    },
    // 判断车运是否为空
    isSTripEmpty() {
      return isSTripEmpty(this.form)
    },
    // 判断空运是否为空
    isLTripEmpty() {
      return isLTripEmpty(this.form)
    },
    isTripEmpty() {
      if (this.trip_type == 1) {
        return this.isSTripEmpty()
      } else {
        return this.isLTripEmpty()
      }
    },
    getDataObj(item = this.form, type = 'detail') {
      if (type == 'detail') {
        let flag =
          (this.trip_type == 1 && !this.isSTripEmpty()) ||
          (this.trip_type == 2 && !this.isLTripEmpty())
        let prefix = this.trip_type == 1 ? 's_' : 'l_'
        if (flag) {
          let contacts = []
          item.contacts.forEach((item1, index) => {
            contacts.push({
              descText: '司机' + (item.contacts.length > 1 ? index + 1 : ''),
              data: (item1['name'] || '暂无') + (item1['phone'] || ''),
              callback: item1['phone']
                ? () => {
                    this.toPage('phone', item1['phone'])
                  }
                : undefined,
              show: this.trip_type == 1,
            })
          })
          return [
            {
              descText: this.trip_type == 1 ? '发车时间' : '起飞时间',
              data: item[prefix + 'starting_time'],
            },
            {
              descText: '车次',

              data: `第${sectionToChinese(item.car_num)}车`,
              show: this.trip_type == 1,
            },
            {
              descText: '车辆',
              show: this.trip_type == 1,
              slot: 'car',
            },
            ...contacts,
            {
              descText: '航班号',
              data: item['number'],
              show: this.trip_type == 2,
            },
            {
              descText: '出发地',
              data: item[prefix + 'start_point'],
            },
            {
              descText: '目的地',
              data: item[prefix + 'end_point'],
            },
            {
              descText: '预计用时',
              data: item[prefix + 'duration'],
            },
            {
              descText: '运费',
              data: item[prefix + 'price'],
              unitText: '元',
            },
            {
              descText: '备注',
              data: item[prefix + 'remark'],
            },
            {
              descText: '操作人',
              data: item.opt_user,
            },
            {
              descText: '操作时间',
              data: item.created_at,
            },
          ]
        }
      } else if (type == 'list') {
        return [
          {
            descText: '关联',
            data: item.re_count,
            unitText: '万',
          },
          {
            descText: '关联箱数',
            data: item.box,
            unitText: '箱',
          },
          {
            descText: '出苗',
            data: item.h_count,
            unitText: '万',
          },
          {
            descText: '出苗箱数',
            data: item.h_box,
            unitText: '箱',
          },
        ]
      } else if (type == 'carDetail') {
        return [
          {
            descText: '车类型',
            data: item.c_model,
          },
          {
            descText: '车载量',
            data: item.load,
          },
          {
            descText: '购买',
            value: this.$dayjs(item.purchase_date).format('YYYY/MM/DD'),
          },
          {
            descText: '年检',
            value: this.$dayjs(item.check_date).format('YYYY/MM/DD'),
          },
          {
            descText: '上次保养',
            value: this.$dayjs(item.pre_mt_date).format('YYYY/MM/DD'),
          },
          {
            descText: '下次保养',
            value: this.$dayjs(item.next_mt_date).format('YYYY/MM/DD'),
          },
          {
            descText: '备注',
            data: item.remark,
          },
        ]
      }
      return []
    },
    showCarDetail(item) {
      this.carShow = true
      this.carData = item
    },
  },
}
</script>

<style lang="less" scoped>
.main-content {
  position: relative;

  .row-between {
    align-items: center;
  }

  .title {
    font-size: 1.2rem;
    color: @titleColor;
    font-weight: 600;
  }

  section {
    padding: 0.5rem;

    .section-title {
      color: @titleColor;
      font-size: 1.2rem;
      font-weight: 700;
    }

    .section-second-title {
      color: @titleColor;
      font-size: 1.1rem;
      font-weight: 600;
      opacity: 0.8;
    }

    .label {
      color: @textColor;
      font-weight: bold;
      opacity: 0.7;
      line-height: 1.2rem;
      font-size: 1rem;
    }

    .info-item {
      display: flex;
      align-items: center;
      margin: 0.2rem 0;
      line-height: 1.4rem;

      .value {
        color: @textColor;
        font-size: 1rem;
        margin-right: 0.5rem;
        line-height: 1.4rem;
      }

      .value.main-c {
        color: @mainColor;
      }
    }

    &.info {
      border-bottom: 0.5rem solid @bgColor;

      .title {
        font-size: 1.2rem;
        font-weight: 700;
      }

      .title.main-c {
        color: @mainColor;
      }
    }

    &.rele {
      border-bottom: 0.5rem solid @bgColor;
    }
  }

  .car {
    margin-right: 0.5rem;
    white-space: nowrap;
  }
}
</style>
