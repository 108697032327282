<template>
  <!-- 下拉刷新组件 -->
  <div
    class="list-container"
    @scroll="scrollEvent"
    :style="{ height: height }"
    v-myLoad="isLoading"
    style="position: relative"
  >
    <van-pull-refresh
      v-model="reLoading"
      @refresh="onRefresh"
      :disabled="pullDisable"
    >
      <slot></slot>
    </van-pull-refresh>
  </div>
</template>

<script>
// emitter: [more, refresh]
// 因为有下拉刷新功能，所以目前只能全局刷新
export default {
  name: 'listContainer',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '100vh',
    },
    pullDisable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: this.loading,
      reLoading: false,
      stop: false,
      timer: undefined,
    }
  },
  watch: {
    loading(val) {
      this.isLoading = val
      if (!val) {
        this.reLoading = false
      }
    },
  },
  methods: {
    scrollEvent(e) {
      if (
        e.srcElement.scrollHeight - e.srcElement.scrollTop <=
        e.srcElement.offsetHeight + 10
      ) {
        if (this.stop) return
        this.$emit('more')
        this.stop = true
        let _this = this
        this.timer = setTimeout(() => {
          _this.stop = false
        }, 500)
      }
    },
    onRefresh() {
      this.reLoading = true
      this.$emit('refresh')
    },
  },
}
</script>

<style lang="less" scoped>
.list-container {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
